<template>
  <div class="home-price-slider-card">
    <div class="card">
      <v-card class="transparent pa-6" width="400" height="427" flat tile>
        <!-- Price -->
        <div class="d-flex">
          <h6 class="heading-h6">Loan amount</h6>
          <v-spacer />
          <h6 class="heading-h6">R {{ price }}</h6>
        </div>

        <div class="mt-3">
          <vue-slider
            v-model="price"
            :min="minPrice"
            :max="maxPrice"
            :interval="interval"
            dotSize="20"
            tooltip="none"
          />
        </div>

        <div class="d-flex">
          <p class="b-body-2 gray700--text">R {{ minPrice }}</p>
          <v-spacer />
          <p class="b-body-2 gray700--text">R {{ maxPrice }}</p>
        </div>

        <!-- Days -->
        <div class="d-flex mt-1">
          <h6 class="heading-h6">Loan period</h6>
          <v-spacer />
          <h6 class="heading-h6">{{ days }} days</h6>
        </div>

        <div class="mt-3">
          <vue-slider
            v-model="days"
            :min="minDays"
            :max="maxDays"
            dotSize="20"
            tooltip="none"
          />
        </div>

        <div class="d-flex">
          <p class="b-body-2 gray700--text">{{ minDays }} days</p>
          <v-spacer />
          <p class="b-body-2 gray700--text">{{ maxDays }} days</p>
        </div>

        <!-- Repayable Amount -->
        <div class="d-flex mt-n3">
          <p class="b-body-1">
            Repayable Amount
            <span class="b-body-1 accent--text font-weight-bold">
              R {{ amountRepayable }}
            </span>
          </p>

          <div class="show-card pointer">
            <!-- img -->
            <v-img
              alt="union"
              class="union pointer mt-1 ml-2"
              contain
              src="@/assets/img/public/pages/home/mobile/union.svg"
              max-width="14"
            />

            <v-card class="icon-card" width="230" height="163" elevation="1">
              <p class="icon-text">
                Interest rate day: 0,16% new clients, 0,1% subsequent clients.
                <br />
                Initial fee: 16,5% of first R 1 000 lent, 10% of loan amount
                over R 1 000, +VAT.Limited 15% of loan amount, +VAT. Service fee
                R 69 month.
              </p>

              <p class="icon-text mt-n1">
                Based on Chapter 5 regulations of NCA no 34 of 2005
              </p>
            </v-card>
          </div>
        </div>

        <!-- Payday -->
        <div class="d-flex mt-n3">
          <p class="b-body-1">
            Payday
            <span class="b-body-1 accent--text font-weight-bold">
              {{ payday }}
            </span>
          </p>
        </div>

        <p class="b-body-2 gray700--text mt-n1">
          Use the loan calculator as reference only. A loan offer can only be
          made once we’ve calculated your affordability.
        </p>

        <v-btn
          :to="{ name: 'RegistrationPage' }"
          class="b-button secondary"
          height="48"
          width="100%"
          elevation="0"
        >
          Get Money
        </v-btn>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "LimeLoansHomepriceslidercard",

  data() {
    return {
      minPrice: 500,
      maxPrice: 8000,
      price: 1700,
      interval: 100,
      minDays: 5,
      maxDays: 37,
      days: 10,
      interest: 24.65,
      amountRepayable: 1246.5,
      payday: "",

      initialFeeAmount: 165,

      serviceFeeFor1stMonth: 6.9,
      serviceFeeFor2ndMonth: 69,

      interestRatePerDay5Percent: 161 / 100000,
      interestRatePerDay3Percent: 1 / 1000,
      interestPerDay: 1.1725,
    };
  },

  mounted() {
    this.payday = this.moment().add(this.days, "d").format("D MMM YYYY");

    this.setAmountRepayable(this.price, this.days);
  },

  watch: {
    price(val) {
      this.setAmountRepayable(val, this.days);
    },

    days(val) {
      this.setAmountRepayable(this.price, val);

      this.payday = this.moment().add(val, "d").format("D MMM YYYY");
    },
  },

  methods: {
    // setAmountRepayable
    setAmountRepayable(price, days) {
      var loanAmount = price; // C2
      var loanPeriod = days; // C3
      var interestRatePercentPerYear = 36 / 100; // C9
      // var interestRatePercentPerMonth = interestRatePercentPerYear / 12; // C10
      var interestRatePercentPerDay = interestRatePercentPerYear / 360; // C11
      var serviceFeePerOneMonth = 69; // C12

      // var serviceFee = this.serviceFeeFor1stMonth;

      var today = this.moment();
      var numberOfDaysInMonth = this.moment(today, "YYYY-MM").daysInMonth(); // 1st month days C15
      var todaysDay = today.format("DD");

      //1st month loan period
      var firstMonthLoanPeriod = numberOfDaysInMonth - todaysDay + 1; // C16
      // var secondMonthLoanPeriod = 0; // C17

      // Loan period: how many month includes
      var numberOfMonths = 1; // C14

      if (numberOfDaysInMonth - todaysDay + 1 < loanPeriod) {
        // serviceFee += this.serviceFeeFor2ndMonth;
        numberOfMonths++;
        // secondMonthLoanPeriod = loanPeriod - firstMonthLoanPeriod; // C17
      }

      // this.interestPerDay = price * this.interestRatePerDay5Percent;

      if (numberOfMonths == 1) {
        firstMonthLoanPeriod = loanPeriod;
      }

      var firstMonthServiceFee =
        (serviceFeePerOneMonth * firstMonthLoanPeriod) / numberOfDaysInMonth; // C19
      var secondMonthServiceFee = 0; // C20

      if (numberOfMonths == 2) {
        secondMonthServiceFee = serviceFeePerOneMonth;
      }

      // =(MIN(IF($C2<1000,165,165+($C2-1000)*0.1),$C2*0.15,1050))*1.15
      var initialFeeAmount = this.initialFeeAmount; // C23

      if (loanAmount >= 1000) {
        // initialFeeAmount = this.initialFeeAmount + loanAmount * 0.1;
        initialFeeAmount = this.initialFeeAmount + (loanAmount - 1000) * 0.1;
      }

      if (initialFeeAmount > loanAmount * 1.15 * 0.15) {
        // initialFeeAmount = loanAmount * 1.15 * 0.15;
        initialFeeAmount = loanAmount * 0.15;
      }

      var initialFeeAmountArray = [
        initialFeeAmount,
        loanAmount * 1.15 * 0.15,
        1050,
      ];

      initialFeeAmount = Math.min(...initialFeeAmountArray) * 1.15;

      var interestPerDay =
        (loanAmount + initialFeeAmount) * interestRatePercentPerDay; //C22

      var interestAmount = interestPerDay * loanPeriod; // C24

      var serviceFeeAmount = firstMonthServiceFee + secondMonthServiceFee; // C25

      // refund = C2 + C23 + C24 + C25
      this.amountRepayable = (
        loanAmount +
        initialFeeAmount +
        interestAmount +
        serviceFeeAmount
      )
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");

      this.days = days;

      // console.log("today", today);
      // console.log("todaysDay", todaysDay);
      // console.log("", today.format("DD"));
      // console.log("numberOfDaysInMonth", numberOfDaysInMonth);
      // console.log("numberOfMonths", numberOfMonths);
      // console.log("interestRatePercentPerMonth", interestRatePercentPerMonth);
      // console.log("interestRatePercentPerDay", interestRatePercentPerDay);
      // console.log("serviceFeePerOneMonth", serviceFeePerOneMonth);

      // console.log("firstMonthLoanPeriod", firstMonthLoanPeriod);
      // // console.log("secondMonthLoanPeriod", secondMonthLoanPeriod);
      // console.log("numberOfMonths", numberOfMonths);

      // console.log("firstMonthServiceFee", firstMonthServiceFee);
      // console.log("secondMonthServiceFee", secondMonthServiceFee);

      // console.log("initialFeeAmount", initialFeeAmount);
      // console.log("interestPerDay", interestPerDay);

      // console.log("interestAmount", interestAmount);

      // console.log("serviceFeeAmount", serviceFeeAmount);
    },
  },
};
</script>

<style lang="scss" scoped>
.heading-h6 {
  font-family: "Raleway";
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;

  font-style: normal;
}

.b-body-2 {
  font-family: "Noto Sans Display";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.01em;
}
.card {
  position: relative;
}

.icon-card {
  /* Auto layout */
  display: none;
  position: absolute;
  top: 150px;
  right: -140px;

  padding: 4px 8px 6px;

  width: 230px;
  height: 163px;

  /* Neutral/00 */

  background: #fff;

  box-shadow: 0px 0px 8px rgba(61, 61, 61, 0.12),
    0px 2px 4px rgba(61, 61, 61, 0.06);
  border-radius: 4px;
  filter: drop-shadow(0px 0px 16px rgba(61, 61, 61, 0.12))
    drop-shadow(0px 4px 8px rgba(61, 61, 61, 0.06));

  z-index: 5;
}

.icon-text {
  //styleName: 12 XS/strike;
  font-family: "Noto Sans Display";
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
}

.show-card:hover .icon-card {
  display: block;
}

// @media (min-width: 769px) and (max-width: 1470px) {
@media only screen and (max-width: 1470px) {
  .icon-card {
    top: 40px;
    right: 10px;
  }
}
</style>
