<template>
  <div class="hero-section">
    <!-- Desktop -->
    <div class="hidden-xs-only">
      <div
        class="bg d-flex align-center justify-center"
        :style="{ backgroundImage: 'url(' + bgImg + ')' }"
      >
        <v-container>
          <v-row align="center">
            <!-- Left -->
            <v-col class="hidden-xs-only" cols="12" sm="6" md="5" lg="4">
              <div class="pr-7">
                <!-- Borrow Safe and Simple -->
                <h1 class="h1-text white--text">
                  Quick and Secure Payday Loans Online
                </h1>

                <h4 class="b-body-1 white--text mt-5">
                  From R 500 to R 8,000 <br />
                  loans with flexible terms up to 37 days <br />
                  Get your payday finance sorted with just a few required
                  documents
                </h4>

                <!-- Required documents -->
                <h3 class="h3-text white--text mt-8 hidden-xs-only">
                  Required documents
                </h3>

                <div class="hidden-xs-only">
                  <!-- Phone -->
                  <div class="d-flex align-center mt-4">
                    <v-img
                      alt="Phone"
                      class="phone"
                      contain
                      src="@/assets/img/public/pages/home/phone.svg"
                      max-width="24"
                    />

                    <p class="b-body-1 white--text ml-4 mb-0">
                      Cellphone number
                    </p>
                  </div>

                  <!-- User -->
                  <div class="d-flex align-center mt-1">
                    <v-img
                      alt="User"
                      class="user"
                      contain
                      src="@/assets/img/public/pages/home/user.svg"
                      max-width="24"
                    />

                    <p class="b-body-1 white--text ml-4 mb-0">SA ID number</p>
                  </div>

                  <!-- Bank -->
                  <div class="d-flex align-center mt-1">
                    <v-img
                      alt="Bank"
                      class="bank"
                      contain
                      src="@/assets/img/public/pages/home/bank.svg"
                      max-width="24"
                    />

                    <p class="b-body-1 white--text ml-4 mb-0">
                      Bank account details
                    </p>
                  </div>

                  <!-- Union -->
                  <div class="d-flex align-start mt-1">
                    <v-img
                      alt="Union"
                      class="union mt-1"
                      contain
                      src="@/assets/img/public/pages/home/union.svg"
                      max-width="24"
                    />

                    <p class="b-body-1 white--text ml-4 mr-3 mb-0">
                      Latest 3 months bank statements showing your income
                    </p>
                  </div>
                </div>
              </div>
            </v-col>

            <!-- Right -->
            <v-col cols="12" sm="6" offset-md="2" md="5" offset-lg="4" lg="4">
              <div class="">
                <HomePriceSliderCard />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <div class="hidden-sm-and-up">
      <div>
        <!-- hero-bg -->
        <v-img
          alt="hero-bg"
          class="hero-bg pa-4"
          src="@/assets/img/public/pages/home/hero-bg.png"
          width="100%"
        >
          <h3 class="mobile-heading-h3 white--text">
            Quick and Secure Payday Loans Online
          </h3>

          <p class="b-body-1 white--text">
            From R500 to R8,000 loans with flexible terms up to 37 days. Get
            your payday finance sorted with just a few required documents
          </p>
        </v-img>
      </div>

      <!-- Mobile -->
      <div class="white pa-4">
        <HomePriceSliderMobileCard />

        <!-- Required documents -->
        <h3 class="h3-text gray900--text">Required documents</h3>

        <div class="pb-2">
          <!-- Phone -->
          <div class="d-flex align-center mt-4">
            <v-img
              alt="Phone"
              class="phone"
              contain
              src="@/assets/img/public/pages/home/mobile/phone.svg"
              max-width="18"
            />

            <p class="b-body-1 gray900--text ml-4 mb-0">Cellphone number</p>
          </div>

          <!-- User -->
          <div class="d-flex align-center mt-1">
            <v-img
              alt="User"
              class="user"
              contain
              src="@/assets/img/public/pages/home/mobile/user.svg"
              max-width="16"
            />

            <p class="b-body-1 gray900--text ml-4 mb-0">SA ID number</p>
          </div>

          <!-- Bank -->
          <div class="d-flex align-center mt-1">
            <v-img
              alt="Bank"
              class="bank"
              contain
              src="@/assets/img/public/pages/home/mobile/bank.svg"
              max-width="18"
            />

            <p class="b-body-1 gray900--text ml-4 mb-0">Bank account details</p>
          </div>

          <!-- Union -->
          <div class="d-flex align-start mt-1">
            <v-img
              alt="Union"
              class="union mt-1"
              contain
              src="@/assets/img/public/pages/home/mobile/union-2.svg"
              max-width="19"
            />

            <p class="b-body-1 gray900--text ml-4 mr-3 mb-0">
              Latest 3 months bank statements showing your income
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomePriceSliderCard from "@/components/shared/cards/HomePriceSliderCard";
import HomePriceSliderMobileCard from "@/components/shared/cards/HomePriceSliderMobileCard";

import bgImg from "@/assets/img/public/pages/home/hero-bg.png";

export default {
  name: "LimeLoansHerosection",

  components: {
    HomePriceSliderCard,
    HomePriceSliderMobileCard,
  },

  data() {
    return { bgImg: bgImg };
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-size: cover;
  background-position: top;
  height: 580px;
  width: 100%;
}

.hero-section {
  .h1-text,
  .h3-text,
  .h4-text {
    font-family: "Raleway";
    font-style: normal;
    margin: 0;
    padding: 0;
  }
  .h1-text {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
  }

  .h3-text {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.10000000149011612px;
  }

  .h4-text {
    //styleName: 24 XL/normal;
    font-family: "Noto Sans Display";
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.01em;
  }

  .mobile-heading-h3 {
    font-family: "Raleway";
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: left;
  }

  @media only screen and (max-width: 600px) {
    .h1-text {
      font-size: 32px;
      line-height: 40px;
    }

    .h3-text {
      //styleName: Header mobile/H2;
      font-family: "Raleway";
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0.10000000149011612px;
    }

    .heading-h4 {
      font-size: 14px;
      line-height: 21px;
    }

    .b-body-1 {
      //styleName: 14 S/normal;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.01em;
    }
  }
}
</style>
